import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';
import { Visibility } from "@material-ui/icons"
import InfoIcon from '@material-ui/icons/Info';
import folderIcon from '../../../assets/images/folder_icon.svg'
import techSpecIcon from '../../../assets/images/tech_specs_icon.svg'
import dataRmIcon from '../../../assets/images/data_rm_icon.svg'
import priorityActiveIcon from '../../../assets/images/priority_active_icon.svg'
import priorityInactiveIcon from '../../../assets/images/priority_inactive_icon.svg'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { checkPermission, getFileSize, getLocalStorageInfo } from '../../../utils';
import { assetTypeValues } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel'
import config from '../../../config';
const AssetList = ({ item, changeOcrPriority, location, history }) => {
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532);
    return (
        <>
            {smbcInstance ?
                <TableRow hover tabIndex={-1} className='asset-list-smbc-row'>
                    <TableCell>
                        <div className="flex-centered">
                                <span className='asset-name'>{item.asset_type == 1 ? item.aircraft_type ? item.aircraft_type.name : '--' : item.engine_type ? item.engine_type.name : '--'}{item.asset_type == 1 ? item.model && '/' + item.model : ''}</span>
                                {
                                    item.ownership_type !== undefined && item.ownership_type.value == 2 && item.owner_portfolio.name && item.owner_portfolio.name != '' ?
                                        <ul className="list-inline">
                                            <li className="list-inline-item portfolio-type">Managed</li>
                                            <li className="list-inline-item">
                                                <Tooltip title={`Portfolio: ${item.owner_portfolio.name}`} arrow>
                                                    <InfoIcon fontSize="small" />
                                                </Tooltip>
                                            </li>
                                        </ul> : null
                                }
                        </div>
                    </TableCell>
                    <TableCell>{item?.msn ? item?.msn : item?.asset_name || '--'}</TableCell>
                    <TableCell>{item?.lessee ? item?.lessee?.name : '--'}</TableCell>
                    <TableCell>{item?.operator?.name || '--'}</TableCell>
                    <TableCell>{item.status && item.status.value === 1 ? (<p className="status on-lease">ON LEASE</p>) : null}
                        {item.status && item.status.value === 2 ? (<p className="status off-lease">OFF LEASE</p>) : null}
                        {item.status && item.status.value === 3 ? (<p className="status on-ground">In Storage</p>) : null}
                        {item.status && item.status.value === 4 ? (<p className="status sold">WRITTEN OFF/ SOLD</p>) : null}
                        {item.status && item.status.value === 0 ? (<p className="status sold">Draft</p>) : null}</TableCell>
                    {/* <TableCell>{item?.on_wing_msn  ? item?.on_wing_msn  : item?.on_wing_msn === null ? "Off Wing" : item?.on_wing_msn === '' ? "On Wing": '--'}</TableCell> */}
                    <TableCell >{item?.on_wing_status ? item?.on_wing_msn ? item?.on_wing_msn : "On Wing" :  "Off Wing"}</TableCell>
                    <TableCell>
                        {assetTypeValues && assetTypeValues[item.asset_type] && assetTypeValues[item.asset_type].urlType ? (
                            <>
                                <ul className='list-inline'>
                                    {item?.record_folders?.technical_records?.uuid ?
                                <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={(e) => { (location.pathname.includes('archived-assets') || item?.archive_status == true) ? history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}?archived-assets`) : history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}`); e.preventDefault(); e.stopPropagation(); trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked Data room icon from Action in Asset listing' }) }}>
                                    <Link>
                                        <Tooltip title="Data Room" arrow>
                                            <img src={dataRmIcon} alt="data room" />
                                        </Tooltip>
                                    </Link>
                                </li> : null
                            }
                                    {!isMobile && item?.record_folders?.technical_records?.count > 0 && checkPermission('records', 'data_room', 'OCR_P') ?
                                        <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked OCR priority icon from Action in Asset listing' }) }}>
                                            <Tooltip title="Change OCR Priority" arrow>
                                                {item.record_folders?.ocr_priority === 4 ?
                                                    <img onClick={() => changeOcrPriority()} src={priorityInactiveIcon} alt="priority" /> :
                                                    <img onClick={() => changeOcrPriority()} src={priorityActiveIcon} alt="priority" />
                                                }
                                            </Tooltip>
                                        </li> : null
                                    }
                                </ul>

                            </>) : null}
                    </TableCell>
                </TableRow>
                :
                <TableRow onClick={() => (location.pathname.includes('archived-assets') || item?.archive_status == true) ? history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}?archived-assets`) : history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}`)}>
                    <TableCell>
                        <div className="flex-centered">
                            <img style={{ width: '40px' }} className="folder-icon" src={folderIcon} alt="Folder Icon" />
                            <div className="asset" style={{ marginLeft: '5px' }}>
                                {(item.asset_type === 1 || item.asset_type === 2 || item.asset_type === 3) ?
                                    <p>
                                        {item.asset_type === 1 ? item.aircraft_type?.name : item.engine_type?.name}{item.asset_type === 3 ? item.apu_type?.name : ''}
                                    </p> : null
                                }
                                <h5>
                                    {assetTypeValues[item.asset_type].label ? assetTypeValues[item.asset_type].label : ''} {item.asset_name}
                                </h5>
                                {location?.pathname.includes('/archived-assets') ? null : item?.archive_status == true ? <span className='archive-chip-class'> Archive </span> : null}
                                {item.current_registration_number ?
                                    <span style={{ fontSize: '11px' }}><b>Reg. No.</b> {item.current_registration_number ? item.current_registration_number : ''}</span> : null
                                }
                            </div>
                        </div>
                    </TableCell>
                    <TableCell>{item?.records_details?.files_count || '--'}</TableCell>
                    <TableCell>{item?.records_details?.files_size ? getFileSize(item.records_details.files_size) : '--'}</TableCell>
                    <TableCell>{item?.records_details?.files_inqueue || '--'}</TableCell>
                    <TableCell>{item?.records_details?.files_ocr_inqueue || '--'}</TableCell>
                    <TableCell>{item?.records_details?.files_to_be_renamed || '--'}</TableCell>
                    <TableCell width={50}>
                        <ul className="list-inline flex-centered">
                            {smbcInstance ? null : <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); window.open(`${config.domain.subDomian}technical-specification/${assetTypeValues[item.asset_type].urlType}/${item.slug}${item?.archive_status == true ? '?archived-assets' : ''}`, '_blank'); trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked Tech Specs icon from Action in Asset listing' }) }}>
                                <Tooltip title="Technical Specification" arrow >
                                    <a><img src={techSpecIcon} alt="Tech Specs" /></a>
                                </Tooltip>
                            </li>}
                            {item?.record_folders?.technical_records?.uuid ?
                                <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={(e) => { (location.pathname.includes('archived-assets') || item?.archive_status == true) ? history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}?archived-assets`) : history.push(`/${assetTypeValues[item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type : item.asset_type].urlType}/${item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug : item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}`); e.preventDefault(); e.stopPropagation(); trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked Data room icon from Action in Asset listing' }) }}>
                                    <Link>
                                        <Tooltip title="Data Room" arrow>
                                            <img src={dataRmIcon} alt="data room" />
                                        </Tooltip>
                                    </Link>
                                </li> : null
                            }
                            {!isMobile && item?.records_details?.files_count > 0 && checkPermission('records', 'data_room', 'OCR_P') ?
                                <li className="list-inline-item" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked OCR priority icon from Action in Asset listing' }) }}>
                                    <Tooltip title="Change OCR Priority" arrow>
                                        {item.ocr_priority === 4 ?
                                            <img onClick={() => changeOcrPriority()} src={priorityInactiveIcon} alt="priority" /> :
                                            <img onClick={() => changeOcrPriority()} src={priorityActiveIcon} alt="priority" />
                                        }
                                    </Tooltip>
                                </li> : null
                            }
                        </ul>
                    </TableCell>
                </TableRow>
            }
        </>
    )
}
export default withRouter(AssetList);